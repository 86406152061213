import React from "react";
import {createMemoryHistory} from "history";
import {Route, Router, Switch} from "react-router-dom";

import "assets/scss/material-kit-react.scss";

// pages for this product
import ContactUs from './contact.js'
import Welcome from './welcome.js'
import Success from './success.js'
import News from './news.js'


let hist = createMemoryHistory();

export default () => (
  <Router history={hist}>
    <Switch>
      <Route path="/contact" component={ContactUs} />
      <Route path="/success" component={Success} />
      <Route path="/news" component={News} />
      <Route path="/" component={Welcome} />
    </Switch>
  </Router>
);
